import React from "react"
import { H1, H2, SideMenu } from "@fastly/consistently"
import { graphql, Link } from "gatsby"
import LayoutCore from "~templates/layout-core"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { MdLink } from "react-icons/md"
import FloatedRSSIcon from "~components/RSSicon"
import { Breadcrumbs, BreadcrumbItem, ComplexHomeText } from "~components/Breadcrumbs/Breadcrumbs"
import FrontmatterPageContext from "~context/FakePageContext"
import { SeoHead } from "~components/Head/SeoHead"
import { FavHead } from "~components/Head/FavHead"
import { CommonHead } from "~components/Head/CommonHead"

import { mainArea } from "~vanilla/layout-default.css"
import { main } from "~vanilla/layout-changelog.css"
import { sideMenu } from "~components/SideMenu/changelog.css"
import { SideMenuChangelog } from "~components/SideMenu/side-menu-changelog"

import { ChangesDateFormatter } from "~components/changes-date-formatter"
import Content from "~partials/changes/significant/index.mdx"

const frontmatter = {
  lang: "en",
  section: "changes",
  section_safe: "changes",
  title: "Fastly Documentation Significant Changes",
}

const ChangesSigIndexPage = ({ data }) => {
  const { recentPosts } = data
  const dateFormatter = ChangesDateFormatter()

  let recentPostsRenders = recentPosts.nodes.map((post) => {
    let m = post.fileAbsolutePath.match(/\/((([0-9]{4})-([0-9]{2})-([0-9]{2}))-(.+))\./)

    const { 1: dateAndSlug, 3: dY, 4: dM, 5: dD, 6: pslug } = m
    //note: 2 contains the YYYY-MM-DD format should you need it

    // construct the url from the parts
    post.url = `/changes/significant/${dY}/${dM}/${dD}/${pslug}`

    const newDate = new Date(Date.UTC(dY, parseInt(dM) - 1, dD, 0, 0, 0, 0))
    const date_s = dateFormatter.format(newDate)
    const dateLink = (
      <i>
        {date_s} -{" "}
        <Link to={post.url} title="permalink">
          <MdLink class="perma" alt="chain icon" />
        </Link>
      </i>
    )

    const rpheader = <H2 id={dateAndSlug}>{post.frontmatter["header"]}</H2>

    return (
      <>
        <hr />
        {rpheader}
        <p>{dateLink}</p>
        <MDXProvider>
          <MDXRenderer>{post.body}</MDXRenderer>
        </MDXProvider>
      </>
    )
  })

  return (
    <>
      <FrontmatterPageContext.Provider value={frontmatter}>
        <LayoutCore>
          <div className={`${mainArea} grid-main`}>
            <SideMenu
              className={`${sideMenu} main-nav section-${frontmatter.section_safe}`}
              aria-label="Navigation between documents"
              data-swiftype-index="false"
            >
              <SideMenuChangelog />
            </SideMenu>

            <main className={`${main} main-content section-${frontmatter.section_safe}`} role="main">
              <div className="content-crumb">
                <Breadcrumbs>
                  <BreadcrumbItem name={<ComplexHomeText />} url="/" />
                  <BreadcrumbItem name="Changes" url="/changes/" />
                  <BreadcrumbItem name="Significant Changes" current />
                </Breadcrumbs>
              </div>

              <div className="content-title">
                <H1 m="0">Significant changes</H1>
              </div>

              <div className="content-content">
                <FloatedRSSIcon
                  url="/changes/significant/rss.xml"
                  title="Link to RSS feed of recent Significant changelog updates"
                />
                <MDXProvider components={{ Link }}>
                  <Content />
                </MDXProvider>
                <span style={{ clear: "right" }}></span>
                {recentPostsRenders}
                <hr />
                Looking for a list of this month's recent content changes? Check out the{" "}
                <Link to="/changes/#current_month">monthly changelog</Link>.
              </div>
            </main>
          </div>
        </LayoutCore>
      </FrontmatterPageContext.Provider>
    </>
  )
}

export default ChangesSigIndexPage

export const query = graphql`
  query {
    recentPosts: allMdx(
      filter: { fileAbsolutePath: { regex: "//_changelog_significant//" }, frontmatter: { published: { ne: false } } }
      limit: 3
      sort: { order: DESC, fields: fileAbsolutePath }
    ) {
      nodes {
        fileAbsolutePath
        body
        frontmatter {
          header
          published
        }
      }
    }
  }
`

export const Head = (props) => {
  return (
    <>
      <title>{frontmatter.title}</title>
      <CommonHead />
      <SeoHead frontmatter={frontmatter} {...props} />
      <FavHead />
    </>
  )
}
