import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`On the dates listed here, we made documentation changes that we believe are significant according to our `}<a parentName="p" {...{
        "href": "https://www.fastly.com/terms"
      }}>{`Terms of Service`}</a>{`. Recent items are shown below, with older items available separately in our `}<a parentName="p" {...{
        "href": "/changes/significant/archive"
      }}>{`archive of significant changes`}</a>{`.`}</p>
    <p>{`You can subscribe to email updates of all documentation changes or just significant changes via the form on this page. We also make significant changes available via a `}<a parentName="p" {...{
        "href": "/changes/significant/rss.xml"
      }}>{`machine-readable feed`}</a>{` for use with RSS-compatible software.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      